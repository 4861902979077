import {HStack, Icon, Menu, MenuButton, Text} from "@chakra-ui/react";
import StyledNavLink from "../../Header/components/StyledNavLink";
import {useAccountFeatures} from "../../hooks/accountFeatures";
import {useIsAdmin} from "../../hooks/roles";
import {useQueryData} from "../../state";
import PortalMenuList from "../../components/PortalMenuList";
import StyledMenuItem from "../../Header/components/StyledMenuItem";
import {
  BookOpenIcon,
  BuildingLibraryIcon,
  ChevronDownIcon,
  CircleStackIcon,
  DocumentIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  ListBulletIcon,
  RectangleGroupIcon,
  ScaleIcon,
  ShieldCheckIcon,
  SparklesIcon,
  Squares2X2Icon,
  UsersIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/solid";
import {Internal} from "../../components/InternalMode";
import RequireAccountFeature from "../../components/RequireAccountFeature";
import {TrustCenterNav} from "./TrustCenter";
import {AccountFeature} from "../../Types";
import {NotificationDot} from "../../components/NotificationDot";

const PoliciesAndInfo = () => {
  const reviewItemsCount = useQueryData({queryKey: ["vendorToolkit", "resolutionsCount", {}]});
  return (
    <Menu placement="bottom-start">
      <MenuButton>
        <StyledNavLink to="library">
          <HStack>
            <Text>Graph</Text>
            {reviewItemsCount > 0 && <NotificationDot />}
            <Icon as={ChevronDownIcon} mt="1px" />
          </HStack>
        </StyledNavLink>
      </MenuButton>
      <PortalMenuList py="0" overflow="hidden">
        <StyledMenuItem to="library/policies" iconComp={DocumentTextIcon} help="Your organisation's policies">
          Policies
        </StyledMenuItem>
        <StyledMenuItem to="library/certifications" iconComp={ShieldCheckIcon} help="ISO27001, SOC2, etc">
          Certifications
        </StyledMenuItem>
        <StyledMenuItem to="library/other-documents" iconComp={DocumentIcon} help="Any other supporting documentation">
          Other documents
        </StyledMenuItem>
        <StyledMenuItem
          to="library/historical-questionnaires"
          iconComp={BuildingLibraryIcon}
          help="Previous answers to questionnaires"
        >
          Historical questionnaires
        </StyledMenuItem>
        <StyledMenuItem to="library/company" iconComp={ScaleIcon} help="Legal information about your organisation">
          Company information
        </StyledMenuItem>
        <StyledMenuItem to="library/subprocessors" iconComp={CircleStackIcon} help="Third-party data processors">
          Subprocessors
        </StyledMenuItem>
        <RequireAccountFeature feature={AccountFeature.CoreResponses}>
          <StyledMenuItem
            to="library/core-responses"
            iconComp={ChatBubbleLeftRightIcon}
            help="Predefined responses to questions"
          >
            Core responses
          </StyledMenuItem>
        </RequireAccountFeature>
        <StyledMenuItem to="library/facts" iconComp={BookOpenIcon} help="Information from your library">
          <HStack>
            <Text>Fact library</Text>
            {reviewItemsCount > 0 && <NotificationDot />}
          </HStack>
        </StyledMenuItem>
        <Internal>
          <StyledMenuItem to="library/clustered-facts" iconComp={ListBulletIcon} help="Information from your library">
            Clustered facts (OLD)
          </StyledMenuItem>
        </Internal>
      </PortalMenuList>
    </Menu>
  );
};

const Configuration = () => (
  <Menu placement="bottom-start">
    <MenuButton>
      <StyledNavLink to="configuration">
        Configuration <Icon as={ChevronDownIcon} ml="1" mt="1px" />
      </StyledNavLink>
    </MenuButton>
    <PortalMenuList py="0" overflow="hidden">
      <StyledMenuItem
        to="configuration/organisation-profile"
        iconComp={InformationCircleIcon}
        help="Your organisation's details"
      >
        Organisation profile
      </StyledMenuItem>
      <StyledMenuItem to="configuration/user-management" iconComp={UsersIcon} help="Manage users and permissions">
        User management
      </StyledMenuItem>
      <StyledMenuItem to="configuration/ai-preferences" iconComp={SparklesIcon} help="Control AI tone and persona">
        AI preferences
      </StyledMenuItem>
      <StyledMenuItem to="configuration/integrations" iconComp={DocumentIcon} help="Integrations with 3rd parties">
        Integrations
      </StyledMenuItem>
      <StyledMenuItem to="configuration/counterparties" iconComp={Squares2X2Icon} help="Partners, vendors, etc.">
        Counterparties
      </StyledMenuItem>
      <RequireAccountFeature feature={AccountFeature.Scopes}>
        <StyledMenuItem to="configuration/scopes" iconComp={RectangleGroupIcon} help="Silos for data">
          Scopes
        </StyledMenuItem>
      </RequireAccountFeature>
    </PortalMenuList>
  </Menu>
);

export default function NavLinks() {
  const isAdmin = useIsAdmin();
  const {enabledFeatures} = useAccountFeatures();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const viewableTrustCenter = enabledFeatures.TrustCenter && (isAdmin || whoami.internal_mode);
  return (
    <>
      <StyledNavLink to="home">Home</StyledNavLink>
      <PoliciesAndInfo />
      <StyledNavLink to="questionnaires">Respond</StyledNavLink>
      <RequireAccountFeature feature={AccountFeature.AdHocQuestions} showInternally>
        <StyledNavLink to="questions">Questions</StyledNavLink>
      </RequireAccountFeature>
      {viewableTrustCenter && <TrustCenterNav />}
      {isAdmin && <Configuration />}
    </>
  );
}
