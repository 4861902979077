import {Box, Text} from "@chakra-ui/react";
import {Fragment, useCallback, useRef, useState} from "react";
import type * as XLSX from "xlsx";
import {SheetState} from "../uploadState";
import * as _ from "lodash-es";
import {useElementSize} from "../../../../../../../hooks/elementSize";

const GRID_LINE_COLOR = "gray.300";
const HEADER_COLOR = "gray.100";
const SCROLL_PADDING = 100;

function normalizeRange(v: XLSX.Range): XLSX.Range {
  return {
    s: {r: Math.min(v.s.r, v.e.r), c: Math.min(v.s.c, v.e.c)},
    e: {r: Math.max(v.s.r, v.e.r), c: Math.max(v.s.c, v.e.c)},
  };
}

export type HighlightedRange = {
  color: string;
  range: XLSX.Range;
  caption?: string;
};

function computeMaxExtent(sheet: XLSX.Sheet, xlsx: typeof XLSX): [number, number] {
  const cells = Object.keys(sheet)
    .filter(k => !k.startsWith("!") && sheet[k].w)
    .map(xlsx.utils.decode_cell);
  let maxRow = 0;
  let maxCol = 0;
  for (const cell of cells) {
    if (cell.r > maxRow) {
      maxRow = cell.r;
    }
    if (cell.c > maxCol) {
      maxCol = cell.c;
    }
  }
  return [maxCol + 1, maxRow + 1];
}

const SheetPreview = ({
  workbook,
  sheetName,
  selection,
  setSelection,
  highlightedRanges,
  xlsx,
}: {
  workbook: XLSX.WorkBook;
  sheetName: string;
  sheetState: SheetState;
  selection?: XLSX.Range | null;
  setSelection?: (newRange: XLSX.Range | null) => void;
  highlightedRanges?: HighlightedRange[];
  xlsx: typeof XLSX;
}) => {
  const isControlled = selection !== undefined;
  const [uncontrolledSelection, setUncontrolledSelection] = useState<XLSX.Range | null>(null);
  if (!isControlled) {
    selection = uncontrolledSelection;
    setSelection = setUncontrolledSelection;
  }
  const selectionStartCell = useRef<XLSX.CellAddress | null>(null);
  const [headerIntersectionRef, {width: rowHeaderWidth = 0, height: colHeaderHeight = 0}] =
    useElementSize<HTMLDivElement>();
  const sheet = workbook.Sheets[sheetName];
  const [numCols, numRows] = computeMaxExtent(sheet, xlsx);
  const rows = new Array(...new Array(numRows).keys()).map(i => xlsx.utils.encode_row(i));
  const cols = new Array(...new Array(numCols).keys()).map(i => xlsx.utils.encode_col(i));

  const mouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const elem = e.target as HTMLDivElement;
      const cellRef = elem.dataset.cell;
      if (cellRef && setSelection && e.buttons === 1) {
        elem.scrollIntoView({behavior: "smooth", block: "nearest"});
        const cellAddress = xlsx.utils.decode_cell(cellRef);
        selectionStartCell.current = cellAddress;
        setSelection({e: cellAddress, s: cellAddress});
      }
    },
    [setSelection, xlsx],
  );
  const mouseUp = useCallback(() => {
    selectionStartCell.current = null;
  }, []);
  const mouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const elem = e.target as HTMLDivElement;
      const cellRef = elem.dataset.cell;
      if (setSelection && cellRef && selectionStartCell.current) {
        elem.scrollIntoView({behavior: "smooth", block: "nearest"});
        const cellAddress = xlsx.utils.decode_cell(cellRef);
        setSelection(normalizeRange({s: selectionStartCell.current, e: cellAddress}));
      }
    },
    [setSelection, xlsx],
  );

  return (
    <Box
      overflow="auto"
      minW="0px"
      userSelect="none"
      scrollPaddingTop={`${colHeaderHeight + SCROLL_PADDING}px`}
      scrollPaddingLeft={`${rowHeaderWidth + SCROLL_PADDING}px`}
      scrollPaddingRight={`${SCROLL_PADDING}px`}
      scrollPaddingBottom={`${SCROLL_PADDING}px`}
      bg={GRID_LINE_COLOR}
      flexBasis="400px"
      flexShrink="0"
      flexGrow="1"
    >
      <Box
        display="grid"
        gridTemplateRows={`repeat(${numRows + 1}, auto)`}
        gridTemplateColumns={`repeat(${numCols + 1}, auto)`}
        gap="1px"
        bg={GRID_LINE_COLOR}
        cursor={setSelection ? "pointer" : "default"}
        w="fit-content"
        sx={{
          "&>*.cell": {
            bg: "white",
            px: "4px",
            py: "1px",
            display: "flex",
            alignItems: "end",
            pointerEvents: "auto",
            maxH: "150px",
            maxW: "250px",
            overflow: "hidden",
          },
        }}
        pointerEvents="none"
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onMouseOver={mouseOver}
        fontSize="sm"
      >
        <Box
          gridColumn={`2 / span ${numCols}`}
          gridRow={1}
          display="grid"
          gridTemplateRows="subgrid"
          gridTemplateColumns="subgrid"
          position="sticky"
          top="0px"
          borderBottom="1px solid"
          marginBottom="-1px"
          borderColor="inherit"
          zIndex="sticky"
        >
          {cols.map((col, i) => (
            <Box key={i} bg={HEADER_COLOR} gridColumn={i + 1} display="flex" justifyContent="center" px={2}>
              {col}
            </Box>
          ))}
        </Box>
        <Box
          gridRow={`2 / span ${numRows}`}
          gridColumn={1}
          display="grid"
          gridTemplateRows="subgrid"
          gridTemplateColumns="subgrid"
          position="sticky"
          left="0px"
          borderRight="1px solid"
          marginRight="-1px"
          borderColor="inherit"
          zIndex="sticky"
        >
          {rows.map((row, i) => (
            <Box
              key={i}
              bg={HEADER_COLOR}
              gridRow={i + 1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="end"
              px={2}
            >
              {row}
            </Box>
          ))}
        </Box>
        <Box
          ref={headerIntersectionRef}
          gridColumn={1}
          gridRow={1}
          bg={HEADER_COLOR}
          top="0px"
          left="0px"
          position="sticky"
          borderBottom="1px solid"
          marginBottom="-1px"
          borderRight="1px solid"
          marginRight="-1px"
          borderColor="inherit"
          zIndex="sticky"
        />
        {rows.map((row, i) => (
          <Fragment key={i}>
            {cols.map((col, j) => (
              <Box key={j} className="cell" gridRow={i + 2} gridColumn={j + 2} data-cell={`${col}${row}`}>
                {sheet[`${col}${row}`]?.w ?? ""}
              </Box>
            ))}
          </Fragment>
        ))}
        {highlightedRanges &&
          highlightedRanges.map((hr, hrIdx) => (
            <Box
              key={hrIdx}
              gridColumn={`${Math.min(hr.range.s.c, numCols) + 2} / ${Math.min(hr.range.e.c, numCols) + 3}`}
              gridRow={`${Math.min(hr.range.s.r, numRows) + 2} / ${Math.min(hr.range.e.r, numRows) + 3}`}
              border="2px dashed"
              borderColor={hr.color}
              color={hr.color}
              overflow="hidden"
            >
              <Box w="0px">
                <Text
                  as="span"
                  bg="rgba(255,255,255,0.75)"
                  fontWeight="semibold"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  minW="0px"
                  position="relative"
                >
                  {hr.caption}
                </Text>
              </Box>
            </Box>
          ))}
        {selection && (
          <Box
            gridColumn={`${selection.s.c + 2} / ${selection.e.c + 3}`}
            gridRow={`${selection.s.r + 2} / ${selection.e.r + 3}`}
            outline="2px solid"
            outlineColor="blue.500"
            zIndex={1}
          />
        )}
      </Box>
    </Box>
  );
};

export default SheetPreview;
