import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {Dispatch, SetStateAction, useCallback, useState} from "react";
import type * as XLSX from "xlsx";
import {SheetState, SheetStates} from "../uploadState";
import * as _ from "lodash-es";
import SheetPreview, {HighlightedRange} from "./SheetPreview";
import {convertRange, unconvertRange} from "../utils";
import {IdentifiedSheetSection} from "../../../../../../../Types";
import {TrashIcon} from "@heroicons/react/20/solid";
import {DragDropContext, Draggable, DropResult, Droppable} from "@hello-pangea/dnd";

type RangeInSheet = {
  sheetName: string;
  range: XLSX.Range;
};

const RangeInput = ({value, onSet, onClear}: {value: string | null; onSet?: () => void; onClear?: () => void}) => {
  return (
    <InputGroup size="md">
      <Input type="text" placeholder="Unset" isReadOnly value={value ?? ""} />
      <InputRightElement width="auto" justifyContent="end" gap={2} pr={2}>
        {onSet && (
          <Button h="1.75rem" size="sm" onClick={onSet}>
            Set
          </Button>
        )}
        {onClear && value && (
          <Button h="1.75rem" size="sm" onClick={onClear}>
            Clear
          </Button>
        )}
      </InputRightElement>
    </InputGroup>
  );
};

const SectionEditor = ({
  section,
  sectionIdx,
  setSheetState,
  currentSelection,
  xlsx,
}: {
  section: IdentifiedSheetSection;
  sectionIdx: number;
  setSheetState: (f: (prevSheetState: SheetState) => SheetState) => void;
  currentSelection: XLSX.Range | null;
  xlsx: typeof XLSX;
}) => {
  const setSectionState = useCallback(
    (f: (prevSectionState: IdentifiedSheetSection) => IdentifiedSheetSection) =>
      setSheetState(prevSheetState => ({
        ...prevSheetState,
        sections: prevSheetState.sections.map((prevSection, prevSectionIdx) =>
          prevSectionIdx === sectionIdx ? f(prevSection) : prevSection,
        ),
      })),
    [sectionIdx, setSheetState],
  );
  const deleteSection = useCallback(
    () =>
      setSheetState(prevSheetState => ({
        ...prevSheetState,
        sections: prevSheetState.sections.filter((_prevSection, prevSectionIdx) => prevSectionIdx !== sectionIdx),
      })),
    [sectionIdx, setSheetState],
  );
  return (
    <Draggable draggableId={sectionIdx.toString()} index={sectionIdx}>
      {({draggableProps, dragHandleProps, innerRef}) => (
        <Stack
          key={sectionIdx}
          ref={innerRef}
          bg="white"
          borderRadius={8}
          gap={2}
          px={2}
          py={4}
          pb={6}
          borderBottom="1px solid"
          borderColor="inherit"
          {...draggableProps}
          {...dragHandleProps}
        >
          <FormControl>
            <HStack justifyContent="space-between" alignItems="baseline">
              <FormLabel>Section title</FormLabel>
              <IconButton
                size="sm"
                aria-label="Delete"
                icon={<Icon as={TrashIcon} />}
                variant="ghost"
                colorScheme="red"
                onClick={deleteSection}
              />
            </HStack>
            <Input
              value={section.section_title}
              onChange={e =>
                setSectionState(prevSectionState => ({...prevSectionState, section_title: e.target.value}))
              }
            />
          </FormControl>
          <HStack>
            <FormControl>
              <FormLabel>Column headers</FormLabel>
              <RangeInput
                value={section.column_headers ? xlsx.utils.encode_range(convertRange(section.column_headers)) : null}
                onSet={
                  currentSelection
                    ? () =>
                        setSectionState(prevSectionState => ({
                          ...prevSectionState,
                          column_headers: unconvertRange(currentSelection),
                        }))
                    : undefined
                }
                onClear={() =>
                  setSectionState(prevSectionState => ({
                    ...prevSectionState,
                    column_headers: undefined,
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Questions</FormLabel>
              <RangeInput
                value={xlsx.utils.encode_range(convertRange(section.questions))}
                onSet={
                  currentSelection
                    ? () =>
                        setSectionState(prevSectionState => ({
                          ...prevSectionState,
                          questions: unconvertRange(currentSelection),
                        }))
                    : undefined
                }
              />
            </FormControl>
          </HStack>
        </Stack>
      )}
    </Draggable>
  );
};

const SectionsEditor = ({
  sheetName,
  sheetState,
  setSheetStates,
  currentSelection,
  xlsx,
}: {
  sheetName: string;
  sheetState: SheetState;
  setSheetStates: Dispatch<SetStateAction<SheetStates>>;
  currentSelection: XLSX.Range | null;
  xlsx: typeof XLSX;
}) => {
  const setSheetState = useCallback(
    (f: (prevSheetState: SheetState) => SheetState) =>
      setSheetStates(prevSheetStates => ({...prevSheetStates, [sheetName]: f(prevSheetStates[sheetName])})),
    [sheetName, setSheetStates],
  );
  const addSection = useCallback(() => {
    setSheetState(prevSheetState => ({
      ...prevSheetState,
      sections: [...prevSheetState.sections, {questions: unconvertRange(currentSelection!), section_title: "Untitled"}],
    }));
  }, [currentSelection, setSheetState]);
  const dragSection = useCallback(
    (result: DropResult) => {
      setSheetState(prevSheetState => ({
        ...prevSheetState,
        sections: prevSheetState.sections.map((prevSection, prevSectionIdx) =>
          prevSectionIdx === result.source.index
            ? prevSheetState.sections[result.destination!.index]
            : prevSectionIdx === result.destination!.index
            ? prevSheetState.sections[result.source.index]
            : prevSection,
        ),
      }));
    },
    [setSheetState],
  );
  return (
    <Box display="flex" flexDir="column" flex="1" gap={4}>
      <DragDropContext onDragEnd={dragSection}>
        <Droppable droppableId="sectionsEditor">
          {({innerRef, droppableProps, placeholder}) => (
            <Stack
              ref={innerRef}
              overflowY="auto"
              display="flex"
              flexDir="column"
              flex="1"
              flexBasis="0px"
              gap={0}
              {...droppableProps}
            >
              {sheetState.sections.map((section, sectionIdx) => (
                <SectionEditor
                  key={sectionIdx}
                  section={section}
                  sectionIdx={sectionIdx}
                  currentSelection={currentSelection}
                  setSheetState={setSheetState}
                  xlsx={xlsx}
                />
              ))}
              {placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
      <Box>
        <Tooltip isDisabled={!!currentSelection} label="Select a range of cells containing questions">
          <Button isDisabled={!currentSelection} w="full" onClick={addSection}>
            Add Section
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

const AnnotationManager = ({
  workbook,
  sheetStates,
  setSheetStates,
  xlsx,
}: {
  workbook: XLSX.WorkBook;
  sheetStates: SheetStates;
  setSheetStates: Dispatch<SetStateAction<SheetStates>>;
  xlsx: typeof XLSX;
}) => {
  const enabledSheetNames = workbook.SheetNames.filter(sheetName => sheetStates[sheetName].enabled);
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(0);
  const selectedSheetName = enabledSheetNames[selectedSheetIndex];
  const selectedSheetState = sheetStates[selectedSheetName];
  const [selectedRange, setSelectedRange] = useState<RangeInSheet | null>(null);
  const highlightedRanges = selectedSheetState.sections
    .flatMap(section => {
      const result: HighlightedRange[] = [];
      result.push({color: "purple.500", caption: section.section_title, range: convertRange(section.questions)});
      if (section.column_headers) {
        result.push({
          color: "pink.500",
          range: convertRange(section.column_headers),
        });
      }
      return result;
    })
    .filter(Boolean);
  const currentSelection = selectedRange?.sheetName === selectedSheetName ? selectedRange.range : null;
  return (
    <Box display="grid" gridTemplateColumns="auto 400px" gap={4} flex="1">
      <Tabs
        isManual
        variant="enclosed-colored-flipped"
        isLazy
        index={selectedSheetIndex}
        onChange={setSelectedSheetIndex}
        minW="0px"
        display="flex"
        flexDir="column"
      >
        <TabPanels display="flex" flexDir="column" flex="1">
          {enabledSheetNames.map(sheetName => (
            <TabPanel
              key={sheetName}
              border="1px solid"
              borderColor="inherit"
              borderBottomWidth="0px"
              p="0px"
              display="flex"
              flexDir="column"
              flex="1"
            >
              <SheetPreview
                workbook={workbook}
                sheetName={sheetName}
                sheetState={sheetStates[sheetName]}
                selection={selectedRange?.sheetName === sheetName ? selectedRange.range : null}
                setSelection={range => setSelectedRange(range && {sheetName, range})}
                highlightedRanges={selectedSheetName === sheetName ? highlightedRanges : []}
                xlsx={xlsx}
              />
            </TabPanel>
          ))}
        </TabPanels>
        <Box overflowX="auto" pt="1px" __css={{scrollbarWidth: "thin"}}>
          <TabList>
            {enabledSheetNames.map(sheetName => (
              <Tab key={sheetName} display="flex" p={0}>
                <Text whiteSpace="nowrap" px={3} py={1}>
                  {sheetName}
                </Text>
              </Tab>
            ))}
          </TabList>
        </Box>
      </Tabs>
      <SectionsEditor
        sheetName={selectedSheetName}
        sheetState={selectedSheetState}
        currentSelection={currentSelection}
        setSheetStates={setSheetStates}
        xlsx={xlsx}
      />
    </Box>
  );
};

export default AnnotationManager;
