import {formatISO} from "date-fns";
import {SourceMin, SourceId, nominate, ThirdPartyId} from "../../Types";

const TODAY = nominate("datetime", formatISO(new Date()));

const SOURCES: SourceMin[] = [
  {
    name: "Supplier Assessment Questionnaire",
    source_id: nominate("sourceId", "Source1"),
    provider: {
      type: "Prevalent",
    },
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: nominate("datetime", "2024-08-01T00:00:00Z"),
  },
  {
    name: "SOC2 Type 2 Report",
    source_id: nominate("sourceId", "Source2"),
    provider: {
      type: "File",
      file_type: "PDF",
    },
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: nominate("datetime", "2024-08-01T00:00:00Z"),
  },
  {
    name: "ISO 27001 Report",
    source_id: nominate("sourceId", "Source3"),
    provider: {
      type: "File",
      file_type: "PDF",
    },
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: nominate("datetime", "2024-08-01T00:00:00Z"),
  },
  {
    name: "Trust Center",
    source_id: nominate("sourceId", "Source4"),
    provider: {type: "Website", url: "https://trust.salesforce.com", monitoring_frequency_days: 1},
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: TODAY,
  },
  {
    name: "Product Documentation",
    source_id: nominate("sourceId", "Source5"),
    provider: {type: "Website", url: "https://security.salesforce.com", monitoring_frequency_days: 1},
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: TODAY,
  },
  {
    name: "Security Scorecard Report",
    source_id: nominate("sourceId", "Source6"),
    provider: {
      type: "File",
      file_type: "PDF",
    },
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: nominate("datetime", "2024-08-01T00:00:00Z"),
  },
  {
    name: "News",
    source_id: nominate("sourceId", "Source7"),
    provider: {type: "Google News", monitoring_frequency_days: 1},
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: TODAY,
  },
  {
    name: "PEPs & Sanctions",
    source_id: nominate("sourceId", "Source8"),
    provider: {type: "ComplyAdvantage", monitoring_frequency_days: 1},
    created_at: nominate("datetime", "2024-08-01T00:00:00Z"),
    updated_at: TODAY,
  },
];

export async function list(_thirdPartyId: ThirdPartyId): Promise<SourceMin[]> {
  return SOURCES;
}

export async function get(sourceId: SourceId): Promise<SourceMin> {
  return SOURCES.find(s => s.source_id === sourceId)!;
}
