import {Alert, AlertDescription, AlertIcon, AlertTitle, Flex, HStack, Icon, Text} from "@chakra-ui/react";
import {useCallback} from "react";
import SingleFileUpload from "../../../../../../components/fileUploads/SingleFileUpload.tsx";
import {CloudArrowUpIcon} from "@heroicons/react/20/solid";
import {FileUpload} from "../../../../../../hooks/fileUpload.ts";
import {UploadState} from "./uploadState.ts";
import WorkbookPreview from "./Preview/WorkbookPreview";

const UploadStep = ({
  parsingFile,
  parseFile,
  setFileUpload,
  fileUpload,
  sheetStates,
  setSheetStates,
  workbook,
  setWorkbook,
  xlsx,
}: UploadState) => {
  const lastError = parsingFile.lastError;

  const handleFileChange = useCallback(
    (fileUpload: FileUpload | null) => {
      if (fileUpload) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          parseFile(fileReader.result as ArrayBuffer);
        };
        fileReader.readAsArrayBuffer(fileUpload.file);
      } else {
        setSheetStates({});
        setWorkbook(null);
      }
      setFileUpload(fileUpload);
    },
    [parseFile, setFileUpload, setSheetStates, setWorkbook],
  );

  return (
    <>
      <SingleFileUpload
        Cls={FileUpload}
        value={fileUpload}
        onChange={handleFileChange}
        accept={{
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx", ".xlsm"],
          "text/csv": [".csv"],
        }}
      >
        {isDragActive => (
          <HStack align="center" spacing={2}>
            <Icon as={CloudArrowUpIcon} fontSize="xl" color={isDragActive ? "blue.500" : "gray.500"} />
            <Text>Drag your spreadsheet here</Text>
          </HStack>
        )}
      </SingleFileUpload>
      {lastError ? (
        <Alert status="error" variant="subtle" flexDirection="column" gap={2}>
          <Flex alignItems="center">
            <AlertIcon />
            <AlertTitle fontSize="md" color="red.700">
              There was a problem with the uploaded file
            </AlertTitle>
          </Flex>
          <AlertDescription fontWeight="thin" fontSize="md" color="red.700" whiteSpace="pre-line" w="full">
            {lastError.toString()}
          </AlertDescription>
        </Alert>
      ) : null}
      {workbook && (
        <WorkbookPreview workbook={workbook} sheetStates={sheetStates} setSheetStates={setSheetStates} xlsx={xlsx} />
      )}
    </>
  );
};

export default UploadStep;
