import {
  Box,
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";
import UploadStep from "./UploadStep.tsx";
import useUploadState from "./uploadState.ts";
import {useState} from "react";
import AnnotateStep from "./AnnotateStep";
import ImportStep from "./ImportStep";
import {QuestionnaireId} from "../../../../../../Types";

const steps = [
  {title: "Upload", description: "Upload the spreadsheet"},
  {title: "Annotate", description: "Identify question ranges"},
  {title: "Import", description: "Import the questions"},
];

const ImportSpreadsheetModal = ({
  questionnaireId,
  onClose,
  navigateTo,
}: {
  questionnaireId: QuestionnaireId;
  onClose: () => void;
  navigateTo?: boolean;
}) => {
  const [step, setStep] = useState(0);
  const uploadState = useUploadState(questionnaireId, onClose, navigateTo);

  return (
    <ModalContent display="flex" flexDirection="column">
      <ModalHeader>Upload spreadsheet</ModalHeader>
      <ModalCloseButton />
      <ModalBody display="flex" flexDir="column" flex="1">
        <Stack spacing={4} flex="1">
          <Stepper index={step} my={4}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          {step === 0 && <UploadStep {...uploadState} />}
          {step === 1 && <AnnotateStep {...uploadState} />}
          {step === 2 && <ImportStep {...uploadState} />}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <HStack spacing="3">
          <Button
            variant="ghost"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          {step !== 0 && <Button onClick={() => setStep(v => v - 1)}>Back</Button>}
          {step !== 2 && <Button onClick={() => setStep(v => v + 1)}>Next</Button>}
          {step === 2 && (
            <Button
              colorScheme="blue"
              isLoading={uploadState.submitting.inProgress}
              isDisabled={uploadState.uploadState?.id !== "Uploaded" || !!uploadState.submitting.lastError}
              onClick={uploadState.submit}
            >
              Import
            </Button>
          )}
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export default ImportSpreadsheetModal;
