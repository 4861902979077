import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  List,
  ListItem,
} from "@chakra-ui/react";
import {SheetStates} from "../uploadState";
import {CreateSection} from "../../../../../../../Types";

const ConvertedSectionsPreview = ({sheetNames, sheetStates}: {sheetNames: string[]; sheetStates: SheetStates}) => {
  const allSections: CreateSection[] = [];
  for (const sheetName of sheetNames) {
    const sheetState = sheetStates[sheetName];
    if (sheetState.enabled) {
      sheetState.sections.forEach((_section, sectionIdx) => {
        const convertedSection = sheetState.convertedSections[sectionIdx];
        if (convertedSection && convertedSection.questions.length > 0) {
          allSections.push(convertedSection);
        }
      });
    }
  }
  return (
    <Accordion>
      {allSections.map((section, sectionIdx) => (
        <AccordionItem key={sectionIdx}>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                {section.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <List>
              {section.questions.map((question, questionIdx) => (
                <ListItem key={questionIdx}>
                  {question.question_number} {question.text}
                </ListItem>
              ))}
            </List>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ConvertedSectionsPreview;
