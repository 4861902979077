import {Box, Button, Divider, Heading, HStack, Icon, Stack, useDisclosure, VStack} from "@chakra-ui/react";
import {BookOpenIcon, CircleStackIcon, InboxIcon, PlusIcon} from "@heroicons/react/24/outline";
import BasePageSideBar from "../../../../../../components/BasePageSideBar.tsx";
import {useRef} from "react";
import SideBarScopeHierarchy from "./SideBarScopeHierarchy";
import CreateOrEditScopeModal from "../../modals/CreateOrEditScopeModal";
import {useQueryData} from "../../../../../../state";
import RequireAccountFeature from "../../../../../../components/RequireAccountFeature";
import {AccountFeature} from "../../../../../../Types";
import CantCreateScopeModal from "../../modals/CantCreateScopeModal";
import {useRelativeLocation} from "../../../../../../hooks/relativeLocation";
import {useSideBarHeight} from "../../../../../../hooks/useSideBarHeight.ts";
import {PAGINATION_NO_LIMIT} from "../../../../../../utils/pagination.ts";

const reviewItemPaths = ["review-items", "resolved-review-items", "ignored-review-items"];

function SideBar() {
  const scopeAxes = useQueryData({queryKey: ["vendorToolkit", "scopeAxes"]});
  const resolutions = useQueryData({queryKey: ["vendorToolkit", "resolutions", {...PAGINATION_NO_LIMIT}]}).items;
  const ignoredResolutions = useQueryData({queryKey: ["vendorToolkit", "ignoredResolutions"]});
  const [relativePath] = useRelativeLocation().split("/");
  const showReviewItemsSubItems = reviewItemPaths.includes(relativePath);

  const createNewScopeModal = useDisclosure();
  const cantCreateScopeModal = useDisclosure();

  const ref = useRef<HTMLDivElement | null>(null);

  useSideBarHeight(ref);

  return (
    <Stack
      ref={ref}
      flex="0 0 auto"
      position="sticky"
      width="360px"
      alignItems="stretch"
      borderRight="1px solid"
      borderColor="gray.100"
      gap="0"
      overflow="hidden"
      top={12}
      background="white"
    >
      <HStack height="64px" px="6" spacing="4" flex="0 0 auto">
        <Icon boxSize={6} as={BookOpenIcon} />
        <Heading size="md">Fact library</Heading>
      </HStack>
      <Divider mb="4" />
      <VStack flex="1 1 auto" alignItems="stretch" gap="0" overflow="hidden">
        <BasePageSideBar href="all" label="All facts" icon={CircleStackIcon} />
        <RequireAccountFeature feature={AccountFeature.ReviewItems}>
          <BasePageSideBar
            href="review-items"
            label="Review items"
            icon={InboxIcon}
            count={resolutions.length}
            notification={resolutions.length > 0 ? "high" : undefined}
            highlightOnSubPath={true}
          />
          {showReviewItemsSubItems && (
            <>
              <BasePageSideBar indent={1} label="Recently resolved" gray href="resolved-review-items" />
              <BasePageSideBar
                indent={1}
                label="Ignored"
                count={ignoredResolutions.length}
                gray
                href="ignored-review-items"
              />
            </>
          )}
        </RequireAccountFeature>
        <Divider mt="4" />
        <VStack
          flex="1 1 auto"
          alignItems="stretch"
          overflow="hidden"
          overflowY="auto"
          overscrollBehavior="contain"
          gap="0"
          style={{scrollbarWidth: "thin"}}
          pt="4"
        >
          <SideBarScopeHierarchy />
        </VStack>
      </VStack>
      <RequireAccountFeature feature={AccountFeature.Scopes}>
        <Box p="4" display="flex" flexFlow="column nowrap" borderTop="1px solid" borderColor="gray.100">
          <Button
            leftIcon={<Icon as={PlusIcon} />}
            colorScheme="green"
            alignSelf="stretch"
            onClick={scopeAxes.length === 0 ? cantCreateScopeModal.onOpen : createNewScopeModal.onOpen}
            isDisabled={scopeAxes.length === 0}
          >
            Create new scope
          </Button>
        </Box>
      </RequireAccountFeature>
      {createNewScopeModal.isOpen && <CreateOrEditScopeModal type="create" {...createNewScopeModal} />}
      {cantCreateScopeModal.isOpen && <CantCreateScopeModal {...cantCreateScopeModal} />}
    </Stack>
  );
}

export default SideBar;
