import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  Table,
  Td as RawTd,
  Tr as RawTr,
  BoxProps,
  Img,
} from "@chakra-ui/react";
import {useQueryData} from "../../../../../state";
import {useParams} from "react-router-dom";
import {SourceId, SourceMin, ThirdPartyId} from "../../../../../Types";
import Page from "../../../../../components/Page";
import {ActionBar} from "../../../../../components/ActionBar";
import ThirdPartyName from "../../../components/ThirdPartyName";
import {ChevronRightIcon, TrashIcon} from "@heroicons/react/24/solid";
import Pdf from "../../../../VendorToolkit/Library/Documents/Pdf";

import pdfUrl from "./soc2.pdf";
import isoUrl from "./iso.pdf";
import securityScorecardUrl from "./scorecard.pdf";
import securityWebsite from "./security.salesforce.com.png";
import trustWebsite from "./trust.salesforce.com.png";

const URLS: {[_id: string]: string} = {
  Source2: pdfUrl,
  Source3: isoUrl,
  Source6: securityScorecardUrl,
};

const PDFFile = ({source}: {source: SourceMin}) => <Pdf downloadUrl={URLS[source.source_id]!} />;

const FileActions = () => (
  <ActionBar
    actionButtons={
      <>
        <Button colorScheme="blue">Replace this file</Button>
        <IconButton icon={<Icon as={TrashIcon} />} colorScheme="red" aria-label="Delete" />
      </>
    }
  >
    <Button colorScheme="green">Download</Button>
  </ActionBar>
);

const File = ({source}: {source: SourceMin}) => {
  if (source.provider.type !== "File") {
    return null;
  }

  switch (source.provider.file_type) {
    case "PDF":
      return (
        <>
          <FileActions />
          <PDFFile source={source} />
        </>
      );
    default:
      return null;
  }
};

const Tr = (props: BoxProps) => <RawTr {...props} />;
const Td = (props: BoxProps) => <RawTd fontSize="sm" p={1} border="1px" borderColor="gray.200" {...props} />;

const Row = ({n}: {n: number}) => (
  <Tr>
    <Td bg="gray.100" textAlign="center" fontWeight="bold" color="gray.500">
      {n}
    </Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
    <Td></Td>
  </Tr>
);

const Prevalent = (_props: {source: SourceMin}) => (
  <>
    <ActionBar>
      <Button colorScheme="green">Refresh</Button>
    </ActionBar>
    <Box p={6}>
      <Table bg="white">
        <Tr bg="gray.100">
          <Td w="4%"></Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            A
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            B
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            C
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            D
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            E
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            F
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            G
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            H
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            I
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            J
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            K
          </Td>
          <Td textAlign="center" fontWeight="bold" color="gray.500" w="8%">
            L
          </Td>
        </Tr>
        {[...Array(20).keys()].map(k => (
          <Row n={k} key={k} />
        ))}
      </Table>
    </Box>
  </>
);

const Website = ({source}: {source: SourceMin}) => {
  if (source.provider.type != "Website") {
    return null;
  }

  return (
    <>
      <ActionBar>
        <Button colorScheme="green">Refresh</Button>
      </ActionBar>
      <Box p={8}>
        <Img src={source.provider.url.includes("security") ? securityWebsite : trustWebsite} rounded="md" />
      </Box>
    </>
  );
};

const SourcePage = () => {
  const {sourceId, thirdPartyId} = useParams() as {sourceId: SourceId; thirdPartyId: ThirdPartyId};
  const thirdParty = useQueryData({queryKey: ["tprm", "thirdParties", thirdPartyId]});
  const source = useQueryData({queryKey: ["tprm", "sources", sourceId]});
  // TODO
  const title = "Test";
  return (
    <Page title={title} display="flex" flexDirection="column">
      <Stack spacing={0} divider={<Divider />} bg="gray.200" flex={1}>
        <HStack divider={<Icon as={ChevronRightIcon} />} p={8} bg="white">
          <Heading size="md">
            <ThirdPartyName thirdParty={thirdParty} />
          </Heading>
          <Heading size="md">{source.name}</Heading>
        </HStack>
        {source.provider.type === "File" ? (
          <File source={source} />
        ) : source.provider.type === "Prevalent" ? (
          <Prevalent source={source} />
        ) : source.provider.type === "Website" ? (
          <Website source={source} />
        ) : null}
      </Stack>
    </Page>
  );
};

export default SourcePage;
