import {Box, Progress} from "@chakra-ui/react";
import {SetStateAction} from "react";
import {SheetStates, UploadState} from "./uploadState.ts";
import {useQuery} from "@tanstack/react-query";
import api from "../../../../../../api";
import type * as XLSX from "xlsx";
import {IdentifiedSheetSection} from "../../../../../../Types";
import {convertRange} from "./utils";
import ConvertedSectionsPreview from "./Preview/ConvertedSections";
import SelectAnsweringMode from "../components/AnsweringMode/Select";

async function extractSectionQuestions(
  sheetName: string,
  sectionIdx: number,
  section: IdentifiedSheetSection,
  sheet: XLSX.Sheet,
  setSheetStates: React.Dispatch<SetStateAction<SheetStates>>,
  xlsx: typeof XLSX,
) {
  const questionsRange = convertRange(section.questions);
  const columnHeadersRange = section.column_headers && convertRange(section.column_headers);
  const questionsAoa: (string | null)[][] = xlsx.utils.sheet_to_json(sheet, {
    header: 1,
    range: questionsRange,
    defval: null,
  });
  const columnHeadersAoa: (string | null)[][] | undefined =
    columnHeadersRange && xlsx.utils.sheet_to_json(sheet, {header: 1, range: columnHeadersRange});
  const combinedAoa = columnHeadersAoa ? [...columnHeadersAoa, ...questionsAoa] : questionsAoa;
  const questions = await api.vendorToolkit.ai.extractSectionQuestions({
    section_title: section.section_title,
    cells: combinedAoa,
  });
  setSheetStates(oldStates => ({
    ...oldStates,
    [sheetName]: {
      ...oldStates[sheetName],
      convertedSections: {
        ...oldStates[sheetName].convertedSections,
        [sectionIdx]: {title: section.section_title ?? "Untitled", questions, description: ""},
      },
    },
  }));
}

const ImportStep = ({sheetStates, setSheetStates, workbook, answeringMode, setAnsweringMode, xlsx}: UploadState) => {
  const state = useQuery({
    queryKey: ["aiImportSpreadsheet", "identifyQuestions"],
    queryFn: async () => {
      const promises: Promise<void>[] = [];
      for (const sheetName of workbook!.SheetNames) {
        if (sheetStates[sheetName].enabled) {
          sheetStates[sheetName].sections.forEach((section, sectionIdx) => {
            promises.push(
              extractSectionQuestions(
                sheetName,
                sectionIdx,
                section,
                workbook!.Sheets[sheetName],
                setSheetStates,
                xlsx,
              ),
            );
          });
        }
      }
      await Promise.allSettled(promises);
      return null;
    },
    staleTime: Infinity,
  });
  const totalCount = Object.values(sheetStates).flatMap(sheetState => sheetState.sections).length;
  const currentCount = Object.values(sheetStates).flatMap(sheetState =>
    Object.values(sheetState.convertedSections),
  ).length;
  return state.isLoading ? (
    <Box>
      <Progress max={totalCount} value={currentCount} isAnimated hasStripe />
    </Box>
  ) : (
    <Box>
      <ConvertedSectionsPreview sheetNames={workbook!.SheetNames} sheetStates={sheetStates} />
      <SelectAnsweringMode value={answeringMode} onChange={setAnsweringMode} />
    </Box>
  );
};

export default ImportStep;
