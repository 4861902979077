import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import {CheckIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import ResponseYesNo from "../../../components/QuestionResponses/ResponseYesNo.tsx";
import ResponseSelect from "../../../components/QuestionResponses/ResponseSelect.tsx";
import ResponseTextarea from "../../../components/QuestionResponses/ResponseTextarea.tsx";
import AttachSupportingDocumentsInline from "../../components/AttachSupportingDocumentationInline.tsx";
import QuestionUsedFacts from "../../components/QuestionUsedFacts.tsx";
import {ActivityStreamView} from "../../../../../components/ActivityStream/index.tsx";
import QuestionStatus from "../../../components/QuestionStatus/Selector.tsx";
import {useCallback} from "react";
import {
  AccountFeature,
  ApiDateTime,
  Owner,
  PromptModifiers,
  Question,
  QuestionId,
  QuestionStatus as QuestionStatusT,
  ResponseOption,
  UserMin,
} from "../../../../../Types.ts";
import api from "../../../../../api/index.ts";
import {getResponseLayer, useLayerType} from "../../../../../hooks/layerType.ts";
import QuestionGeneratedFacts from "../../components/QuestionGeneratedFacts.tsx";
import AttachSupportingDocumentsButton from "../../components/AttachSupportingDocumentationButton.tsx";
import {WatchingQuestionSelector} from "../../../components/WatchingSelector.tsx";
import {useQueryData} from "../../../../../state/index.ts";
import {usePromiseState} from "../../../../../hooks/promiseState.ts";
import {SendToReviewButton} from "../../../components/SendToReviewButton.tsx";
import {useNavigate} from "react-router-dom";
import QuestionNumber from "../../components/QuestionNumber.tsx";
import {useLocalStorage} from "usehooks-ts";
import AutoCompleteButton from "../AutoCompleteButton.tsx";
import DueDate from "../../../../../components/DueDate.tsx";
import EditQuestionButton from "./EditQuestionButton.tsx";
import QuestionText from "../../components/QuestionText.tsx";
import QuestionGuidance from "../../components/QuestionGuidance.tsx";
import {useKeyboardShortcuts} from "../../../../../hooks/keyboardShortcuts.ts";
import OwnerSelector from "../../../../../components/OwnerSelector.tsx";
import ModifiersButton from "../../../components/PromptModifiers/ModifiersButton.tsx";
import RequireAccountFeature from "../../../../../components/RequireAccountFeature.tsx";
import CoreResponseSelector from "./components/CoreResponses/CoreResponseSelector.tsx";
import UsingCoreResponse from "./components/CoreResponses/UsingCoreResponse.tsx";
import User from "../../../../../components/User";

type QuestionParent = {promptModifiers: PromptModifiers} & ({type: "Questionnaire"} | {type: "Account"});

function QuestionActionBar({
  question,
  parent,
  nextQuestionId,
  prevQuestionId,
}: {
  question: Question;
  parent: QuestionParent;
  nextQuestionId?: QuestionId;
  prevQuestionId?: QuestionId;
}) {
  const [updatingPromptModifiers, updatePromptModifiers] = usePromiseState(
    async (newValue: PromptModifiers) => {
      await api.vendorToolkit.questions.updatePromptModifiers(question.question_id, newValue);
    },
    [question.question_id],
  );

  const [layerType] = useLayerType();
  const {question_id, response_layers} = question;
  const {status} = getResponseLayer(response_layers, layerType);

  const autoCompleteDisabled = status !== QuestionStatusT.Respond;
  const autoCompleteTooltipText = autoCompleteDisabled ? `Question must have status "Respond".` : undefined;
  const navigate = useNavigate();

  const [completing, complete] = usePromiseState(async () => {
    await api.vendorToolkit.questions.updateStatus(question_id, layerType, QuestionStatusT.Complete);

    if (nextQuestionId !== undefined) {
      navigate(`../${nextQuestionId}`);
    }
  }, [navigate, nextQuestionId, layerType, question_id]);

  return (
    <HStack justifyContent="space-between" p="4" bg="gray.50" borderBottom="1px" borderColor="gray.200" wrap="wrap">
      <HStack>
        <Button
          leftIcon={<Icon as={ChevronLeftIcon} />}
          isDisabled={prevQuestionId === undefined}
          onClick={() => {
            if (prevQuestionId) {
              navigate(`../${prevQuestionId}`);
            }
          }}
          size="sm"
        >
          Previous
        </Button>
        <Button
          rightIcon={<Icon as={ChevronRightIcon} />}
          isDisabled={nextQuestionId === undefined}
          onClick={() => {
            if (nextQuestionId) {
              navigate(`../${nextQuestionId}`);
            }
          }}
          size="sm"
        >
          Next
        </Button>
      </HStack>
      <HStack>
        <RequireAccountFeature feature={AccountFeature.ResponseWizard} showInternally>
          <HStack>
            <ButtonGroup isAttached colorScheme="purple" ml="auto">
              <AutoCompleteButton
                target="Question"
                questionId={question.question_id}
                isDisabled={autoCompleteDisabled}
                tooltipLabel={autoCompleteTooltipText}
                buttonProps={{size: "sm"}}
              />
              <ModifiersButton
                value={question.prompt_modifiers}
                usedValue={question.used_prompt_modifiers}
                defaultValue={parent.promptModifiers}
                parentLabel={parent.type}
                onChange={updatePromptModifiers}
                isLoading={updatingPromptModifiers.inProgress}
                isDisabled={updatingPromptModifiers.inProgress}
              />
            </ButtonGroup>
          </HStack>
        </RequireAccountFeature>
        <SendToReviewButton questionId={question_id} nextQuestionId={nextQuestionId} buttonProps={{size: "sm"}} />
        <Button
          colorScheme="green"
          leftIcon={<Icon as={CheckIcon} />}
          rightIcon={nextQuestionId !== undefined ? <Icon as={ChevronRightIcon} /> : undefined}
          isLoading={completing.inProgress}
          onClick={complete}
          isDisabled={status === QuestionStatusT.Complete}
          size="sm"
        >
          Complete
        </Button>
      </HStack>
    </HStack>
  );
}

function QuestionHeader({question, parent, asker}: {question: Question; parent: QuestionParent; asker?: UserMin}) {
  const [layerType] = useLayerType();
  const {question_id, response_layers} = question;
  const {owner, status, due_date} = getResponseLayer(response_layers, layerType);

  const onNudge = useCallback(async () => {
    await api.vendorToolkit.questions.nudge(question_id, layerType);
  }, [question_id, layerType]);

  const onReassign = useCallback(
    async (owner?: Owner) => {
      await api.vendorToolkit.questions.assign(question_id, layerType, owner?.owner_id ?? null);
    },
    [question_id, layerType],
  );

  const onSetStatus = useCallback(
    async (status: QuestionStatusT) => {
      await api.vendorToolkit.questions.updateStatus(question_id, layerType, status);
    },
    [question_id, layerType],
  );

  const onSetDueDate = useCallback(
    async (dueDate?: ApiDateTime) => {
      await api.vendorToolkit.questions.updateDueDate(question_id, layerType, dueDate ?? null);
    },
    [question_id, layerType],
  );

  return (
    <Stack p="6" borderBottom="1px" borderColor="gray.200" height="fit-content" spacing="4">
      <HStack justifyContent="space-between" alignItems="start" spacing="8">
        <Stack gap={2} flex={"1"}>
          <Heading as="h2" size="md">
            <HStack gap={2} alignItems="baseline">
              {asker && (
                <HStack gap={2} alignItems="center" transform="translate(0, -2px)">
                  <User user={asker} />
                  <Text fontSize="md" color="gray.500">
                    asked:
                  </Text>
                </HStack>
              )}
              <QuestionNumber pt="4px" question={question} fontSize="md" isDisabled={false} />
              <QuestionText question={question} />
            </HStack>
          </Heading>
          <QuestionGuidance question={question} />
          <HStack alignItems="flex-start" wrap="wrap" pt={2}>
            <OwnerSelector owner={owner} onNudge={onNudge} onReassign={onReassign} />
            <QuestionStatus status={status} onChangeStatus={onSetStatus} />
            {parent.type === "Questionnaire" && (
              <DueDate
                dueDate={due_date}
                complete={status === QuestionStatusT.Complete}
                onDateChange={onSetDueDate}
                showTextPrefix
                data-testid="due-date-selector"
              />
            )}
          </HStack>
        </Stack>
        <EditQuestionButton question={question} />
      </HStack>
      {status === QuestionStatusT.Automating && (
        <Box>
          <Alert status="info" borderRadius="md" as={HStack} spacing="6" p="6">
            <AlertIcon boxSize="40px" mr="0" />
            <Box>
              <AlertTitle fontSize="md">This question is being automatically completed by Platformed's AI.</AlertTitle>
              <AlertDescription fontSize="md">
                <Text>You cannot edit the question while it is in this state.</Text>
                <Text>You can change the question status but this will cancel automation.</Text>
              </AlertDescription>
            </Box>
            <Box ml="auto">
              <Spinner thickness="4px" speed="1s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Box>
          </Alert>
        </Box>
      )}
    </Stack>
  );
}

function QuestionResponseSection({question}: {question: Question}) {
  const [layerType] = useLayerType();
  const {question_id, parts, response_layers} = question;
  const {response_text, response_select, response_yes_no, status, linked_core_response} = getResponseLayer(
    response_layers,
    layerType,
  );

  const isEditingResponseDisabled = status === QuestionStatusT.Automating;

  const onSetResponseYesNo = useCallback(
    async (response: boolean | undefined) => {
      await api.vendorToolkit.questions.updateResponseYesNo(question_id, layerType, response);
    },
    [question_id, layerType],
  );

  const onSetResponseSelect = useCallback(
    async (response: ResponseOption[] | undefined) => {
      await api.vendorToolkit.questions.updateResponseSelect(question_id, layerType, response);
    },
    [question_id, layerType],
  );

  const onSetResponseText = useCallback(
    async (response: string) => {
      await api.vendorToolkit.questions.updateResponseText(question_id, layerType, response || null);
    },
    [question_id, layerType],
  );

  return (
    <Stack p="6" spacing="6" borderBottom="1px" borderColor="gray.200">
      <Text fontWeight="600" fontSize="lg" color="gray.600">
        Response
      </Text>
      {parts.yes_no.enabled.content && (
        <ResponseYesNo value={response_yes_no} onCommit={onSetResponseYesNo} isDisabled={isEditingResponseDisabled} />
      )}
      {parts.select.enabled.content && (
        <ResponseSelect
          value={response_select}
          config={parts.select.config}
          onCommit={onSetResponseSelect}
          isDisabled={isEditingResponseDisabled}
        />
      )}
      {parts.files.enabled.content && !parts.text.enabled.content && (
        <Box>
          <AttachSupportingDocumentsButton question={question} width={"50%"} />
        </Box>
      )}
      {parts.text.enabled.content && (
        <ResponseTextarea
          value={response_text ?? ""}
          onCommit={onSetResponseText}
          wordCountLimit={parts.text.config.word_count_limit}
          isDisabled={isEditingResponseDisabled || linked_core_response != null}
          bottomAttachedComponent={
            parts.files.enabled.content && <AttachSupportingDocumentsInline question={question} />
          }
        />
      )}
      <RequireAccountFeature feature={AccountFeature.CoreResponses}>
        {status !== QuestionStatusT.Automating && (
          <>
            {linked_core_response == null && <CoreResponseSelector question={question} />}
            {linked_core_response != null && (
              <UsingCoreResponse question={question} coreResponse={linked_core_response} />
            )}
          </>
        )}
      </RequireAccountFeature>
    </Stack>
  );
}

function QuestionReviewSection({question}: {question: Question}) {
  const [layerType] = useLayerType();
  const {ai_review_comment, ai_review_good} = getResponseLayer(question.response_layers, layerType);
  return (
    ai_review_comment && (
      <Stack p="6" spacing="6" borderBottom="1px" borderColor="gray.200">
        <HStack>
          <Text fontWeight="600" fontSize="lg" color="gray.600">
            AI Review
          </Text>
          {<Tag colorScheme={ai_review_good ? "green" : "red"}>{ai_review_good ? "Good answer" : "Bad answer"}</Tag>}
        </HStack>
        <Text fontSize="md" whiteSpace="pre-wrap">
          {ai_review_comment}
        </Text>
      </Stack>
    )
  );
}

function QuestionFactsSection({question}: {question: Question}) {
  const {question_id} = question;
  const [active, setActive] = useLocalStorage<number | number[]>("questionFactsExpanded", 0);

  const numUsedFacts = useQueryData({queryKey: ["vendorToolkit", "questionUsedFacts", question_id]}).length;
  const numGeneratedFacts = useQueryData({queryKey: ["vendorToolkit", "questionGeneratedFacts", question_id]}).length;

  const hasFacts = numUsedFacts > 0 || numGeneratedFacts > 0;

  return (
    <Accordion allowToggle index={hasFacts ? active : -1} onChange={setActive}>
      <AccordionItem isDisabled={!hasFacts} borderTop={"none"}>
        <AccordionButton p="6">
          <HStack alignItems="center" justifyContent="space-between" flex="1 0 auto">
            <Text fontWeight="600" fontSize="lg" color="gray.600">
              Facts ({numUsedFacts} used, {numGeneratedFacts} generated)
            </Text>
            {hasFacts && <AccordionIcon />}
          </HStack>
        </AccordionButton>
        <AccordionPanel p={6}>
          <Stack spacing={2}>
            <QuestionUsedFacts questionId={question_id} />
            <QuestionGeneratedFacts questionId={question_id} />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

function QuestionActivitySection({question}: {question: Question}) {
  const [layerType] = useLayerType();
  const {question_id, response_layers} = question;
  const {activity_stream} = getResponseLayer(response_layers, layerType);

  return (
    <Box p="6">
      {activity_stream ? (
        <ActivityStreamView
          activityStream={activity_stream}
          watchSelector={<WatchingQuestionSelector questionId={question_id} />}
        />
      ) : (
        <Text fontWeight="600" color="gray.600">
          No activity on this layer
        </Text>
      )}
    </Box>
  );
}

const SingleQuestionView = ({
  question,
  nextQuestionId,
  prevQuestionId,
  parent,
  asker,
}: {
  question: Question;
  nextQuestionId?: QuestionId;
  prevQuestionId?: QuestionId;
  parent: QuestionParent;
  asker?: UserMin;
}) => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const navigate = useNavigate();
  const [layerType] = useLayerType();

  useKeyboardShortcuts(
    whoami.internal_mode
      ? {
          ArrowRight: () => nextQuestionId && navigate(`../${nextQuestionId}`),
          ArrowLeft: () => prevQuestionId && navigate(`../${prevQuestionId}`),
          Digit1: () =>
            api.vendorToolkit.questions.updateStatus(question.question_id, layerType, QuestionStatusT.Respond),
          Digit2: () =>
            api.vendorToolkit.questions.updateStatus(question.question_id, layerType, QuestionStatusT.Review),
          Digit3: () =>
            api.vendorToolkit.questions.updateStatus(question.question_id, layerType, QuestionStatusT.Complete),
          KeyU: () => api.vendorToolkit.questions.assign(question.question_id, layerType, null),
          KeyA: () => api.vendorToolkit.questions.assign(question.question_id, layerType, whoami.user_owner!.owner_id),
        }
      : {},
    {alt: true},
  );

  return (
    <Stack spacing="0" flex="1" overflow="hidden">
      <QuestionActionBar
        question={question}
        parent={parent}
        nextQuestionId={nextQuestionId}
        prevQuestionId={prevQuestionId}
      />
      <QuestionHeader question={question} parent={parent} asker={asker} />
      <QuestionResponseSection question={question} />
      <RequireAccountFeature feature={AccountFeature.CsvImport} showInternally>
        <QuestionReviewSection question={question} />
      </RequireAccountFeature>
      <QuestionFactsSection question={question} />
      <QuestionActivitySection question={question} />
    </Stack>
  );
};

export default SingleQuestionView;
