import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkBox,
  LinkOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import {useOutletContext, Link as RouterLink} from "react-router-dom";
import {SourceMin, SourceProvider, ThirdParty} from "../../../../Types";
import {ActionBar, ActionBarSearch} from "../../../../components/ActionBar";
import {ArrowPathIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {useQueryData} from "../../../../state";
import {unreachableCase} from "../../../../utils/typescript";
import CompanyLogo from "../../components/CompanyLogo";
import UpdatedAt from "../../components/UpdatedAt";

const MonitoredIcon = ({frequency}: {frequency: number}) => (
  <Tooltip
    label={`Monitored every ${frequency > 1 ? frequency + " " : ""}day${frequency > 1 ? "s" : ""}`}
    placement="top"
  >
    <HStack gap="2px" bg="green.50" color="green.500">
      <Icon as={ArrowPathIcon} fontSize="lg" p="2px" rounded="full" />
      <Text fontSize="xs" fontWeight="semibold">
        MONITORED
      </Text>
    </HStack>
  </Tooltip>
);

const Provider = ({provider}: {provider: SourceProvider}) => {
  switch (provider.type) {
    case "File":
      return (
        <Stack gap={1}>
          <Text>Uploaded File</Text>
          <Text color="gray.500" fontSize="sm">
            {provider.file_type}
          </Text>
        </Stack>
      );
    case "Website":
      return (
        <Stack gap={1}>
          <Text>Website Monitoring</Text>
          <Link color="blue.500" fontSize="sm" href={provider.url} target="_blank">
            {provider.url}
          </Link>
        </Stack>
      );
    case "Google News":
      return (
        <HStack>
          <CompanyLogo name="Google News" domain="news.google.com" size="2xs" />
          <Text>Google News</Text>
        </HStack>
      );
    case "ComplyAdvantage":
      return (
        <HStack>
          <CompanyLogo name="ComplyAdvantage" domain="complyadvantage.com" size="2xs" />
          <Text>ComplyAdvantage</Text>
        </HStack>
      );
    case "Prevalent":
      return (
        <HStack>
          <CompanyLogo name="Prevalent" size="2xs" bg="#5b48fb" />
          <Text>Prevalent</Text>
        </HStack>
      );
    default:
      unreachableCase(provider);
  }
};

const SourceRow = ({source}: {source: SourceMin}) => (
  <LinkBox as={Tr} transform="scale(1)" _hover={{bg: "gray.50", cursor: "pointer"}} fontSize="md" color="gray.700">
    <Td>
      <Checkbox />
    </Td>
    <Td fontWeight="semibold">
      <LinkOverlay as={RouterLink} to={source.source_id} />
      <Stack gap={0}>
        <Text>{source.name}</Text>
      </Stack>
    </Td>
    <Td>
      <Provider provider={source.provider} />
    </Td>
    <Td>
      <UpdatedAt
        updatedAt={source.updated_at}
        monitoredComp={
          (source.provider.type == "Website" ||
            source.provider.type == "Google News" ||
            source.provider.type == "ComplyAdvantage") && (
            <MonitoredIcon frequency={source.provider.monitoring_frequency_days!} />
          )
        }
      />
    </Td>
    <Td align="right">
      <IconButton icon={<Icon as={ChevronRightIcon} fontSize="xl" />} aria-label="View Document" variant="ghost" />
    </Td>
  </LinkBox>
);

const SourcesPage = () => {
  const thirdParty: ThirdParty = useOutletContext();
  const sources: SourceMin[] = useQueryData({
    queryKey: ["tprm", "thirdPartySources", thirdParty.third_party_id],
  });

  return (
    <Stack spacing={0} pb={12} divider={<Divider borderColor="gray.200" />} justify="flex-start">
      <ActionBar
        actionButtons={
          <ButtonGroup colorScheme="green">
            <Button>Add Source</Button>
            <Button>Upload File</Button>
          </ButtonGroup>
        }
      >
        <ActionBarSearch />
      </ActionBar>
      <Table style={{borderCollapse: "separate", borderSpacing: "0"}} size="lg" layout="fixed">
        <Thead bg="white" borderBottom="10px solid" borderBottomColor="red.100">
          <Tr color="red">
            <Th width="5%" color="gray.500" p="0">
              <Checkbox px="8" py="5" />
            </Th>
            <Th width="40%" color="gray.500">
              Name
            </Th>
            <Th width="30%" color="gray.500">
              Type
            </Th>
            <Th width="15%" color="gray.500">
              Last Updated
            </Th>
            <Th w="10%" />
          </Tr>
        </Thead>
        <Tbody>
          {sources.map(s => (
            <SourceRow source={s} key={s.source_id} />
          ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

export default SourcesPage;
