import {Box, Checkbox, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import {Dispatch, SetStateAction} from "react";
import type * as XLSX from "xlsx";
import {SheetStates} from "../uploadState";
import * as _ from "lodash-es";
import SheetPreview from "./SheetPreview";

const WorkbookPreview = ({
  workbook,
  sheetStates,
  setSheetStates,
  xlsx,
}: {
  workbook: XLSX.WorkBook;
  sheetStates: SheetStates;
  setSheetStates: Dispatch<SetStateAction<SheetStates>>;
  xlsx: typeof XLSX;
}) => {
  return (
    <Tabs isManual variant="enclosed-colored-flipped" isLazy minW="0px" display="flex" flexDir="column" flex="1">
      <TabPanels display="flex" flexDir="column" flex="1">
        {workbook.SheetNames.map(sheetName => (
          <TabPanel
            key={sheetName}
            border="1px solid"
            borderColor="inherit"
            borderBottomWidth="0px"
            p="0px"
            display="flex"
            flexDir="column"
            flex="1"
          >
            <SheetPreview workbook={workbook} sheetName={sheetName} sheetState={sheetStates[sheetName]} xlsx={xlsx} />
          </TabPanel>
        ))}
      </TabPanels>
      <Box overflowX="auto" pt="1px" __css={{scrollbarWidth: "thin"}}>
        <TabList>
          {workbook.SheetNames.map(sheetName => (
            <Tab key={sheetName} display="flex" p={0}>
              <Box display="flex" onClick={e => e.stopPropagation()}>
                <Checkbox
                  isChecked={sheetStates[sheetName].enabled}
                  onChange={e => {
                    setSheetStates(_.set(_.cloneDeep(sheetStates), [sheetName, "enabled"], e.target.checked));
                  }}
                  p={2}
                  pl={3}
                ></Checkbox>
              </Box>
              <Text whiteSpace="nowrap" pr={3} py={1}>
                {sheetName}
              </Text>
            </Tab>
          ))}
        </TabList>
      </Box>
    </Tabs>
  );
};

export default WorkbookPreview;
