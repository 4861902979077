import {Box, Progress} from "@chakra-ui/react";
import {SetStateAction} from "react";
import {SheetStates, UploadState} from "./uploadState.ts";
import {useQuery} from "@tanstack/react-query";
import api from "../../../../../../api";
import type * as XLSX from "xlsx";
import AnnotationManager from "./Preview/AnnotationManager";

const MAX_LENGTH = 64;

type CellValue = string | number | undefined | null;

function truncateCells(aoa: CellValue[][]): (string | undefined)[][] {
  for (const row of aoa) {
    for (let i = 0; i < row.length; ++i) {
      let v = row[i];
      if (v != null) {
        v = v.toString();
        if (v.length > MAX_LENGTH) {
          v = v.slice(0, MAX_LENGTH) + "...";
        }
        row[i] = v;
      } else {
        delete row[i];
      }
    }
  }
  return aoa as (string | undefined)[][];
}

function arrayToObject<T>(a: (T | undefined)[]): {[i: number]: T} {
  return Object.fromEntries(a.map((v, i) => [i, v]).filter(Boolean));
}

function arraysToObjects<T>(aoa: (T | undefined)[][]) {
  return arrayToObject(aoa.map(arrayToObject));
}

async function identifySheetSections(
  sheetName: string,
  sheet: XLSX.Sheet,
  setSheetStates: React.Dispatch<SetStateAction<SheetStates>>,
  xlsx: typeof XLSX,
) {
  const aoa: CellValue[][] = xlsx.utils.sheet_to_json(sheet, {header: 1});
  const truncatedAoa = truncateCells(aoa);
  const ooo = arraysToObjects(truncatedAoa);
  const sections = await api.vendorToolkit.ai.identifySheetSections(ooo);
  setSheetStates(oldStates => ({
    ...oldStates,
    [sheetName]: {...oldStates[sheetName], identifiedSections: true, sections},
  }));
}

const AnnotateStep = ({sheetStates, setSheetStates, workbook, xlsx}: UploadState) => {
  const state = useQuery({
    queryKey: ["aiImportSpreadsheet", "identifySections"],
    queryFn: async () => {
      const promises: Promise<void>[] = [];
      for (const sheetName of workbook!.SheetNames) {
        if (sheetStates[sheetName].enabled) {
          promises.push(identifySheetSections(sheetName, workbook!.Sheets[sheetName], setSheetStates, xlsx));
        }
      }
      await Promise.allSettled(promises);
      return null;
    },
    staleTime: Infinity,
  });
  return state.isLoading ? (
    <Box>
      <Progress
        max={Object.values(sheetStates).length}
        value={Object.values(sheetStates).filter(s => s.identifiedSections).length}
        isAnimated
        hasStripe
      />
    </Box>
  ) : (
    <AnnotationManager sheetStates={sheetStates} setSheetStates={setSheetStates} workbook={workbook!} xlsx={xlsx} />
  );
};

export default AnnotateStep;
