import * as thirdParties from "./thirdparties";
import * as frameworks from "./frameworks";
import * as frameworkVersions from "./frameworkVersions";
import * as assessments from "./assessments";
import * as sources from "./sources";
import * as risks from "./risks";
import * as thirdPartyTiers from "./thirdPartyTiers";

const tprm = {thirdParties, thirdPartyTiers, frameworks, frameworkVersions, assessments, sources, risks};

export default tprm;
