import {HStack, Stack, Text, TextProps} from "@chakra-ui/react";
import * as datefns from "date-fns";
import {ApiDateTime} from "../../../Types";
import {parseISO} from "../../../utils/date";

const Relative = ({updatedAt, ...props}: {updatedAt: ApiDateTime} & TextProps) => {
  let date = parseISO(updatedAt);
  date = datefns.add(date, {days: 0});
  let days = datefns.differenceInCalendarDays(Date.now(), date);
  const future = days < 0;
  days = Math.abs(days);

  const color = future ? "red.500" : "gray.700";
  const s = days > 1 ? "s" : "";

  return (
    <Text as="span" color={color} whiteSpace="nowrap" {...props}>
      {days === 0 ? "Today" : future ? `Unknown` : `${days} day${s} ago`}
    </Text>
  );
};

const UpdatedAt = ({updatedAt, monitoredComp}: {updatedAt: ApiDateTime; monitoredComp: React.ReactNode}) => (
  <Stack gap={1}>
    <HStack>
      <Relative updatedAt={updatedAt} />
      {monitoredComp}
    </HStack>
    <Text color="gray.500" fontSize="sm">
      {updatedAt ? datefns.format(parseISO(updatedAt), "PP") : "-"}
    </Text>
  </Stack>
);

export default UpdatedAt;
