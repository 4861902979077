import {
  Box,
  Text,
  Icon,
  HStack,
  Spinner,
  Stack,
  IconButton,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import {Card, CardHeading} from "../Card";
import {withSuspense} from "../../../../state/withSuspense";
import {useQueryData} from "../../../../state";
import {useLayerType} from "../../../../hooks/layerType";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {useCallback, useState} from "react";
import {QuestionsTable} from "../components/QuestionsTable";
import {ReviewItemsTable} from "../components/ReviewItemsTable";
import RequireAccountFeature from "../../../../components/RequireAccountFeature";
import {AccountFeature} from "../../../../Types";

function Empty({text}: {text: React.ReactNode}) {
  return (
    <Stack p={32} alignItems="center" spacing={4} bg="gray.50">
      <Icon as={CheckCircleIcon} color="green.500" boxSize={8} />
      <Text fontSize="md" color="gray.500">
        {text}
      </Text>
    </Stack>
  );
}

const AssignedToYouCard = withSuspense(
  () => {
    const PAGE_SIZE = 20;
    const [layerType] = useLayerType();

    const questionsCount = useQueryData({queryKey: ["vendorToolkit", "tasks", "count", layerType]});
    const resolutionsCount = useQueryData({queryKey: ["vendorToolkit", "resolutionsCount", {assigned_to_me: true}]});

    const [tabIndex, setTabIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const selectedTabCount = tabIndex === 0 ? questionsCount : resolutionsCount;

    const handleChangeTab = useCallback((index: number) => {
      setTabIndex(index);
      setOffset(0);
    }, []);

    const start = selectedTabCount === 0 ? 0 : offset + 1;
    const end = Math.min(offset + PAGE_SIZE, selectedTabCount);

    return (
      <Card>
        <Tabs isManual isLazy size="md" index={tabIndex} onChange={handleChangeTab}>
          <HStack
            fontSize="md"
            fontWeight={600}
            color="gray.600"
            borderBottom="1px solid"
            borderColor="gray.200"
            spacing={4}
            pr={2}
          >
            <Text p={4}>Assigned to you:</Text>
            <TabList alignSelf="stretch">
              <Tab>Questions ({questionsCount})</Tab>
              <RequireAccountFeature feature={AccountFeature.ReviewItems}>
                <Tab>Review items ({resolutionsCount})</Tab>
              </RequireAccountFeature>
            </TabList>
            <Box flex={1} />
            <HStack spacing={4}>
              <Text fontWeight={400}>
                {start}-{end} of {selectedTabCount}
              </Text>
              <HStack spacing={2}>
                <IconButton
                  size="sm"
                  fontSize="lg"
                  icon={<Icon as={ChevronLeftIcon} />}
                  aria-label="Previous"
                  onClick={() => setOffset(o => Math.max(o - PAGE_SIZE, 0))}
                  isDisabled={offset === 0}
                />
                <IconButton
                  size="sm"
                  fontSize="lg"
                  icon={<Icon as={ChevronRightIcon} />}
                  aria-label="Next"
                  onClick={() => setOffset(o => o + PAGE_SIZE)}
                  isDisabled={end === selectedTabCount}
                />
              </HStack>
            </HStack>
          </HStack>
          <TabPanels>
            <TabPanel p={0}>
              {questionsCount === 0 ? (
                <Empty text="There are no questions assigned to you!" />
              ) : (
                <QuestionsTable offset={offset} limit={PAGE_SIZE} />
              )}
            </TabPanel>
            <TabPanel p={0}>
              {resolutionsCount === 0 ? (
                <Empty text="There are no review items assigned to you!" />
              ) : (
                <ReviewItemsTable offset={offset} limit={PAGE_SIZE} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    );
  },
  <Card>
    <CardHeading hasBorder>Assigned to you:</CardHeading>
    <Box p={8} display="grid" placeItems="center">
      <Spinner size="sm" />
    </Box>
  </Card>,
);

export default AssignedToYouCard;
