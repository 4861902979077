import {useAsyncOperation} from "../../../../../../hooks/asyncOperation";
import {FileUpload, FileUploadBaseState} from "../../../../../../hooks/fileUpload";
import {PromiseState, PromiseStateReturnType, usePromiseState} from "../../../../../../hooks/promiseState";
import {Dispatch, SetStateAction, useState} from "react";
import {AnsweringMode} from "../components/AnsweringMode";
import type * as XLSX from "xlsx";
import {unmergeCellsInWorkbook} from "./importSpreadsheet";
import {IdentifiedSheetSection, File, CreateSection, QuestionStatus, QuestionnaireId} from "../../../../../../Types";
import {queryClient, useQueryData} from "../../../../../../state";
import api from "../../../../../../api";
import {formatISO} from "../../../../../../utils/date";
import {router} from "../../../../../../router";
import {useXLSX} from "../../../../../../hooks/import";
import {useAbortable} from "../../../../../../hooks/abortable";

export type SheetState = {
  enabled: boolean;
  identifiedSections: boolean;
  sections: IdentifiedSheetSection[];
  convertedSections: {[sectionIdx: number]: CreateSection};
};

export type SheetStates = {[sheetName: string]: SheetState};

export type UploadState = {
  xlsx: typeof XLSX;
  workbook: XLSX.WorkBook | null;
  setWorkbook: Dispatch<SetStateAction<XLSX.WorkBook | null>>;
  sheetStates: SheetStates;
  setSheetStates: Dispatch<SetStateAction<SheetStates>>;
  fileUpload: FileUpload | null;
  setFileUpload: Dispatch<SetStateAction<FileUpload | null>>;
  uploadState: FileUploadBaseState<File> | null;
  answeringMode: AnsweringMode;
  setAnsweringMode: Dispatch<SetStateAction<AnsweringMode>>;
  parsingFile: PromiseState<void, [buffer: ArrayBuffer]>;
  parseFile: (buffer: ArrayBuffer) => Promise<PromiseStateReturnType<void>>;
  clearParseFile: () => void;
  submitting: PromiseState<void, []>;
  submit: () => Promise<PromiseStateReturnType<void>>;
  clearSubmit: () => void;
};

export default function useUploadState(
  questionnaire_id: QuestionnaireId,
  onClose: () => void,
  navigateTo?: boolean,
): UploadState {
  const xlsx = useXLSX();
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
  const [sheetStates, setSheetStates] = useState<SheetStates>({});
  const [fileUpload, setFileUpload] = useAbortable(useState<FileUpload | null>(null));
  const uploadState = useAsyncOperation(fileUpload);
  const [answeringMode, setAnsweringMode] = useState(AnsweringMode.Considered);
  const [parsingFile, parseFile, clearParseFile] = usePromiseState(
    (buffer: ArrayBuffer) => {
      const wb = xlsx.read(buffer);
      unmergeCellsInWorkbook(wb, xlsx);
      setWorkbook(wb);
      queryClient.resetQueries({queryKey: ["aiImportSpreadsheet"]});
      setSheetStates(
        Object.fromEntries(
          wb.SheetNames.map((sheetName, sheetIndex) => [
            sheetName,
            {
              enabled: !wb.Workbook?.Sheets?.[sheetIndex]?.Hidden,
              sections: [],
              identifiedSections: false,
              convertedSections: {},
            },
          ]),
        ),
      );
    },
    [setWorkbook, xlsx],
  );

  const [submitting, submit, clearSubmit] = usePromiseState(async () => {
    if (uploadState?.id !== "Uploaded" || !workbook) return;
    const allSections: CreateSection[] = [];
    for (const sheetName of workbook.SheetNames) {
      const sheet = sheetStates[sheetName];
      if (sheet.enabled) {
        sheet.sections.forEach((_section, sectionIdx) => {
          const convertedSection = sheet.convertedSections[sectionIdx];
          if (convertedSection && convertedSection.questions.length > 0) {
            allSections.push(convertedSection);
          }
        });
      }
    }
    if (answeringMode !== AnsweringMode.None) {
      for (const section of allSections) {
        for (const question of section.questions) {
          if (question.response_layer.status === QuestionStatus.Respond) {
            question.response_layer.status = QuestionStatus.Automating;
          }
        }
      }
    }
    await api.vendorToolkit.questionnaires.importQuestions(questionnaire_id, {
      sections: allSections,
      questions: [],
      automate: answeringMode === AnsweringMode.Instant,
    });
    await api.vendorToolkit.questionnaires.createOriginalFile(questionnaire_id, uploadState.result.file_id);
    if (!whoami.internal_mode) {
      await api.vendorToolkit.questionnaires.updateOriginalFileImportedAt(
        questionnaire_id,
        uploadState.result.file_id,
        formatISO(new Date()),
      );
    }
    onClose();
    if (navigateTo) {
      router!.navigate(`/vendor-toolkit/questionnaires/${questionnaire_id}/questions`);
    }
  }, [onClose, navigateTo, uploadState, answeringMode, questionnaire_id, whoami.internal_mode, sheetStates, workbook]);

  return {
    xlsx,
    workbook,
    setWorkbook,
    sheetStates,
    setSheetStates,
    fileUpload,
    setFileUpload,
    uploadState,
    answeringMode,
    setAnsweringMode,
    parsingFile,
    parseFile,
    clearParseFile,
    submitting,
    submit,
    clearSubmit,
  };
}
