import {Navigate, Route} from "react-router-dom";
import ProductFrame from "../ProductFrame";
import NavLinks from "./NavLinks";
import {AccountFeature} from "../../Types";
import {Box} from "@chakra-ui/react";

import thirdPartiesRoutes from "./ThirdParties";
import thirdPartyRoutes from "./ThirdParty";

export default (
  <Route
    path="tprm"
    element={
      <ProductFrame bgBaseColor="gray" productFeature={AccountFeature.TPRM}>
        <NavLinks />
      </ProductFrame>
    }
  >
    {thirdPartiesRoutes}
    {thirdPartyRoutes}
    <Route path="frameworks" element={<Box>Frameworks</Box>} handle={{crumb: "Frameworks"}} />
    <Route index element={<Navigate to="thirdparties" replace />} handle={{crumb: "Third Parties"}} />
  </Route>
);
