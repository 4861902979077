import {Heading, HStack, Stack} from "@chakra-ui/react";
import Page from "../../../components/Page.tsx";
import {withSuspense} from "../../../state/withSuspense.tsx";
import QuickActionsCard from "./cards/QuickActions.tsx";
import AssignedToYouCard from "./cards/AssignedToYou.tsx";
import TaskCountsCard from "./cards/TaskCounts.tsx";
import OpenQuestionnairesCard from "./cards/OpenQuestionnaires.tsx";
import {useQueryData} from "../../../state/index.ts";
import GettingStarted from "./components/GettingStarted.tsx";
import RequireAccountFeature from "../../../components/RequireAccountFeature.tsx";
import {AccountFeature} from "../../../Types.ts";

const TasksPage = withSuspense(() => {
  const whoami = useQueryData({queryKey: ["whoAmI"]});
  const gettingStartedChecklist = useQueryData({queryKey: ["vendorToolkit", "gettingStartedChecklist"]});

  return (
    <Page title="Home">
      <Stack h="full" spacing={0}>
        <GettingStarted checklist={gettingStartedChecklist} />
        <Heading size="md" p="8">
          Welcome back, {whoami.user.name.split(" ")[0]}
        </Heading>
        <Stack p={8} pt="0">
          <HStack alignItems="stretch" flexWrap="wrap">
            <RequireAccountFeature feature={AccountFeature.ReviewItems}>
              <TaskCountsCard flexGrow="1" />
            </RequireAccountFeature>
            <OpenQuestionnairesCard flexGrow="3" />
            <QuickActionsCard />
          </HStack>
          <AssignedToYouCard />
        </Stack>
      </Stack>
    </Page>
  );
});

export default TasksPage;
